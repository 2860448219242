import _wildcard from "wildcard";
var exports = {};
var wildcard = _wildcard;
var reMimePartSplit = /[\/\+\.]/;
/**
  # mime-match

  A simple function to checker whether a target mime type matches a mime-type
  pattern (e.g. image/jpeg matches image/jpeg OR image/*).

  ## Example Usage

  <<< example.js

**/

exports = function (target, pattern) {
  function test(pattern) {
    var result = wildcard(pattern, target, reMimePartSplit); // ensure that we have a valid mime type (should have two parts)

    return result && result.length >= 2;
  }

  return pattern ? test(pattern.split(";")[0]) : test;
};

export default exports;